import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/PrivateRoute";
import SignIn from "../components/pages/SignIn";
import ChangePassword from "../components/pages/ChangePassword";
import ResetPassword from "../components/pages/ResetPassword";
import Profile from "../components/pages/Profile";
import { isProduction } from "../lib/utils/globalVars";
// import { navigate } from "gatsby";

const Account = () => {
  if (isProduction) {
    // navigate('/');
    return <div>Old Account</div>;
  }

  return (
    <Router>
      <PrivateRoute path="/account/profile" component={Profile} />
      <ResetPassword path="/account/reset-password" />
      <ChangePassword path="/account/change-password" />
      <SignIn path="/account/signin" />
    </Router>
  );
};

export default Account;
