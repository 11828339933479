import React from "react";
import { navigate } from "gatsby";
import { isAuthenticated } from "../lib/auth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (process.env.GATSBY_ACTIVE_ENV !== "local") {
    if (!isAuthenticated() && location.pathname !== `/account/signin`) {
      navigate("/account/signin");
      return null;
    }
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
