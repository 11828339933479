import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Box,
  Flex,
  Container,
  Heading,
  // Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  ButtonGroup,
  useToast,
} from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import useUser from "../../lib/auth";
import Layout from "../../layouts/Layout";

const ProfilePage = () => {
  const { heroBg } = useStaticQuery(
    graphql`
      query ProfilePageQuery {
        heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
      }
    `
  );

  const toast = useToast();
  const { loading, user } = useUser();
  const [showOld, setShowOld] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState({
    given_name: ``,
    family_name: ``,
    email: ``,
    oldPassword: ``,
    password: ``,
    passwordConfirm: ``,
  });

  useEffect(() => {
    setValues({
      ...values,
      given_name: user.given_name || ``,
      family_name: user.family_name || ``,
      email: user.email || ``,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let { oldPassword, password, passwordConfirm, ...attributes } = values;

    const currentUser = await Auth.currentAuthenticatedUser();

    if (!currentUser) {
      return toast({
        title: "No Current Authenticated User",
        description: "Please sign in again.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }

    if (oldPassword && password && passwordConfirm) {
      if (password !== passwordConfirm) {
        return toast({
          title: "Passwords Don't Match",
          description: "Please re-enter matching passwords.",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }

      Auth.changePassword(currentUser, oldPassword, password)
        .then(() => {
          toast({
            title: "Successfully Updated Password",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
          setValues({
            ...values,
            oldPassword: ``,
            password: ``,
            passwordConfirm: ``,
          });
        })
        .catch((err) => {
          toast({
            title: err.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        });

      Auth.updateUserAttributes(currentUser, attributes);
    } else {
      Auth.updateUserAttributes(currentUser, attributes)
        .then(() => {
          toast({
            title: "Successfully Updated Profile",
            status: "success",
            duration: 6000,
            isClosable: true,
          });
        })
        .catch((err) => {
          toast({
            title: err.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        });
    }
  };

  if (loading || !user) return null;

  return (
    <Layout pageTitle="Profile" backgroundImage={heroBg}>
      <Container textAlign="center" maxW="550px" p="80px 30px">
        <Box mb={12}>
          <Heading as="h2">Your Profile</Heading>
          {/* <Text>Duis aute irure dolor in reprehenderit in voluptate.</Text> */}
        </Box>
        <Box as="form" method="post" onSubmit={handleSubmit}>
          <FormControl id="given_name">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                First Name
              </FormLabel>
              <Input
                variant="filled"
                // placeholder="First Name"
                value={values.given_name}
                onChange={handleChange}
              />
            </Flex>
          </FormControl>

          <FormControl id="family_name">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                Last Name
              </FormLabel>
              <Input
                variant="filled"
                // placeholder="Last Name"
                value={values.family_name}
                onChange={handleChange}
              />
            </Flex>
          </FormControl>

          <FormControl id="email">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                Email
              </FormLabel>
              <Input
                variant="filled"
                // placeholder="Email"
                value={values.email}
                onChange={handleChange}
              />
            </Flex>
          </FormControl>

          <FormControl id="oldPassword">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                Old Password
              </FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={showOld ? "text" : "password"}
                  // placeholder="Old Password"
                  value={values.oldPassword}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShowOld(!showOld)}
                  >
                    {showOld ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <FormControl id="password">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                New Password
              </FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  // placeholder="New Password"
                  value={values.password}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <FormControl id="passwordConfirm">
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={10}
            >
              <FormLabel whiteSpace="nowrap" minW="160px">
                Confirm Password
              </FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={showConfirm ? "text" : "password"}
                  // placeholder="Confirm Password"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShowConfirm(!showConfirm)}
                  >
                    {showConfirm ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <ButtonGroup variant="outline" spacing="6">
            {/* <Button
              color="blue.500"
              bgColor="blue.50"
              border="none"
              minW="195px"
              onClick={() => console.log("Cancel changes...")}
            >
              Cancel
            </Button> */}
            <Button type="submit" variant="solid" minW="195px">
              Save Changes
            </Button>
          </ButtonGroup>
        </Box>
      </Container>
    </Layout>
  );
};

export default ProfilePage;
