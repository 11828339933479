import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Auth } from "aws-amplify";
import * as queryString from "query-string";
// import { setLocalUser, normalizeUserData } from "../../lib/auth";
import {
  Box,
  Flex,
  Container,
  Heading,
  // Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useToast,
} from "@chakra-ui/react";
import Layout from "../../layouts/Layout";
// import Link from "../elements/Link";

const ChangePasswordPage = ({ location }) => {
  const { heroBg } = useStaticQuery(
    graphql`
      query ChangePasswordPageQuery {
        heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
      }
    `
  );

  const toast = useToast();
  const [showUsername, setShowUsername] = useState(false);
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [values, setValues] = useState({
    username: ``,
    password: ``,
    newPassword: ``,
    passwordConfirm: ``,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!values.username) {
      toast({
        title: "Username is missing",
        description: "Please enter your username",
        status: "error",
        duration: 6000,
        isClosable: true,
      });

      return setShowUsername(true);
    }

    const user = await Auth.signIn(values.username, values.password);

    if (!user.challengeName) {
      toast({
        title: "Password Change Already Completed",
        description: "You will be redirected momentarily.",
        status: "info",
        duration: 6000,
        isClosable: true,
      });
      setTimeout(() => {
        navigate("/");
      }, 6000);
      return;
    }

    if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
      if (values.newPassword !== values.passwordConfirm) {
        return toast({
          title: "Passwords Don't Match",
          description: "Please re-enter matching passwords.",
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }

      Auth.completeNewPassword(user, values.newPassword)
        .then(() => {
          // const currentUser = await Auth.currentAuthenticatedUser()
          //   setLocalUser(normalizeUserData(currentUser));

          toast({
            title: "Password Successfully Changed",
            description: "You will be redirected to your profile momentarily.",
            status: "success",
            duration: 6000,
            isClosable: true,
          });

          setTimeout(() => {
            navigate("/account/profile");
          }, 6000);
        })
        .catch((err) => {
          toast({
            title: "Error",
            description: err.message,
            status: "error",
            duration: 6000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    const { username } = queryString.parse(location.search);
    setValues({
      ...values,
      username: username || "",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Layout pageTitle="Change Password" backgroundImage={heroBg}>
      <Container textAlign="center" maxW="550px" p="80px 30px">
        <Box mb={12}>
          <Heading as="h2">Change Your Password</Heading>
          {/* <Text>Duis aute irure dolor in reprehenderit in voluptate.</Text> */}
        </Box>
        <Box as="form" method="post" onSubmit={handleSubmit}>
          <FormControl id="username" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              {showUsername && <FormLabel minW="40%">Username</FormLabel>}
              <Input
                type={showUsername ? "text" : "hidden"}
                variant="filled"
                value={values.username}
                onChange={handleChange}
              />
            </Flex>
          </FormControl>

          <FormControl id="password" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel minW="40%">Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  // placeholder="Enter password"
                  value={values.password}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <FormControl id="newPassword" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel minW="40%">New Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  // placeholder="Enter password"
                  value={values.newPassword}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShowNew(!showNew)}
                  >
                    {showNew ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <FormControl id="passwordConfirm" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={10}
            >
              <FormLabel minW="40%">Confirm New Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={showConfirm ? "text" : "password"}
                  // placeholder="Confirm password"
                  value={values.passwordConfirm}
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShowConfirm(!showConfirm)}
                  >
                    {showConfirm ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          {/* <Text mb={10}>
            Lost your verification code?{" "}
            <Button variant="link" onClick={handleReset}>
              Resend Code
            </Button>
          </Text> */}

          <Button type="submit" minW="195px">
            Change Password
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default ChangePasswordPage;
