import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { Auth } from "aws-amplify";
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useToast,
} from "@chakra-ui/react";
import Layout from "../../layouts/Layout";
import Link from "../elements/Link";

const ResetPasswordPage = () => {
  const { heroBg } = useStaticQuery(
    graphql`
      query ResetPasswordPageQuery {
        heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
      }
    `
  );

  const toast = useToast();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [step, setStep] = useState(0);
  const [values, setValues] = useState({
    resetCode: ``,
    email: ``,
    password: ``,
    passwordConfirm: ``,
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.id]: event.target.value,
    });
  };

  const handleReset = async (e) => {
    e.preventDefault();

    await Auth.forgotPassword(values.email)
      .then(() => {
        toast({
          title: "Reset Code Sent",
          description: "Please check your email for the reset code.",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        setStep(1);
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (values.password !== values.passwordConfirm) {
      return toast({
        title: "Passwords Don't Match",
        description: "Please re-enter matching passwords.",
        status: "error",
        duration: 6000,
        isClosable: true,
      });
    }

    Auth.forgotPasswordSubmit(values.email, values.resetCode, values.password)
      .then(() => {
        toast({
          title: "Password Successfully Reset",
          description: "You will be redirected to your profile momentarily.",
          status: "success",
          duration: 6000,
          isClosable: true,
        });
        setTimeout(() => {
          navigate("/account/profile");
        }, 6000);
      })
      .catch((err) => {
        toast({
          title: "Error",
          description: err.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      });
  };

  return (
    <Layout pageTitle="Reset Password" backgroundImage={heroBg}>
      <Container textAlign="center" maxW="550px" p="80px 30px">
        <Box mb={12}>
          <Heading as="h2">Reset Your Password</Heading>
          {/* <Text>Duis aute irure dolor in reprehenderit in voluptate.</Text> */}
        </Box>
        {step === 0 ? (
          <Box as="form" method="post" onSubmit={handleReset}>
            <FormControl id="email" isRequired>
              <Flex
                flexDirection={["column", "row"]}
                alignItems={["flex-start", "center"]}
                mb={10}
              >
                <FormLabel whiteSpace="nowrap" minW="160px">
                  Email
                </FormLabel>
                <Input
                  variant="filled"
                  // placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>

            <Text mb={10}>
              <Link variant="link" to="/account/signin">
                Go back to sign in...
              </Link>
            </Text>

            <Button type="submit" minW="195px">
              Send Reset Code
            </Button>
          </Box>
        ) : (
          <Box as="form" method="post" onSubmit={handleSubmit}>
            <FormControl id="resetCode" isRequired>
              <Flex
                flexDirection={["column", "row"]}
                alignItems={["flex-start", "center"]}
                mb={3}
              >
                <FormLabel whiteSpace="nowrap" minW="160px">
                  Reset Code
                </FormLabel>
                <Input
                  variant="filled"
                  // placeholder="Reset Code"
                  value={values.resetCode}
                  onChange={handleChange}
                />
              </Flex>
            </FormControl>

            <FormControl id="password" isRequired>
              <Flex
                flexDirection={["column", "row"]}
                alignItems={["flex-start", "center"]}
                mb={3}
              >
                <FormLabel whiteSpace="nowrap" minW="160px">
                  Password
                </FormLabel>
                <InputGroup>
                  <Input
                    variant="filled"
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    // placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      variant="ghost"
                      h="1.5rem"
                      size="xs"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </FormControl>

            <FormControl id="passwordConfirm" isRequired>
              <Flex
                flexDirection={["column", "row"]}
                alignItems={["flex-start", "center"]}
                mb={10}
              >
                <FormLabel whiteSpace="nowrap" minW="160px">
                  Confirm Password
                </FormLabel>
                <InputGroup>
                  <Input
                    variant="filled"
                    pr="4.5rem"
                    type={showConfirm ? "text" : "password"}
                    // placeholder="Confirm password"
                    value={values.passwordConfirm}
                    onChange={handleChange}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      variant="ghost"
                      h="1.5rem"
                      size="xs"
                      onClick={() => setShowConfirm(!showConfirm)}
                    >
                      {showConfirm ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </FormControl>

            <Text mb={10}>
              Lost your verification code?{" "}
              <Button variant="link" onClick={handleReset}>
                Resend Code
              </Button>
            </Text>

            <Button type="submit" minW="195px">
              Reset Password
            </Button>
          </Box>
        )}
      </Container>
    </Layout>
  );
};

export default ResetPasswordPage;
