import React, { useState } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import { signIn, isAuthenticated } from "../../lib/auth";
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  FormControl,
  FormLabel,
  // FormErrorMessage,
  // FormHelperText,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  useToast,
} from "@chakra-ui/react";
import Link from "../elements/Link";
import Layout from "../../layouts/Layout";

const SignInPage = () => {
  const { heroBg } = useStaticQuery(
    graphql`
      query SignInPageQuery {
        heroBg: file(relativePath: { eq: "hero-bg-1.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
          extension
          publicURL
        }
      }
    `
  );

  const toast = useToast();
  const [show, setShow] = useState(false);
  const [credentials, setCredentials] = useState({
    username: ``,
    password: ``,
  });

  const handleChange = (event) => {
    setCredentials({
      ...credentials,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    signIn(credentials, ({ status, ...res }) => {
      if (status === "error") {
        return toast({
          title: res.message,
          status: "error",
          duration: 6000,
          isClosable: true,
        });
      }

      toast({
        title: "Successfully Signed In",
        status: "success",
        duration: 6000,
        isClosable: true,
      });
      navigate(`/docs/ontology`);
    });
  };

  if (isAuthenticated()) {
    navigate(`/docs/ontology`);
    return null;
  }

  return (
    <Layout pageTitle="Sign In" backgroundImage={heroBg}>
      <Container textAlign="center" maxW="450px" p="80px 30px">
        <Box mb={12}>
          <Heading as="h2">Please Sign In</Heading>
          {/* <Text>Duis aute irure dolor in reprehenderit in voluptate.</Text> */}
        </Box>
        <Box as="form" method="post" onSubmit={handleSubmit}>
          <FormControl id="username" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={3}
            >
              <FormLabel whiteSpace="nowrap" minW="100px">
                Email
              </FormLabel>
              <Input
                variant="filled"
                // placeholder="Email"
                onChange={handleChange}
              />
            </Flex>
          </FormControl>

          <FormControl id="password" isRequired>
            <Flex
              flexDirection={["column", "row"]}
              alignItems={["flex-start", "center"]}
              mb={10}
            >
              <FormLabel whiteSpace="nowrap" minW="100px">
                Password
              </FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  pr="4.5rem"
                  type={show ? "text" : "password"}
                  // placeholder="Enter password"
                  onChange={handleChange}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    variant="ghost"
                    h="1.5rem"
                    size="xs"
                    onClick={() => setShow(!show)}
                  >
                    {show ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          </FormControl>

          <Text mb={10}>
            Forgot your password?{" "}
            <Link
              variant="link"
              textDecoration="underline"
              ml={1}
              to="/account/reset-password"
            >
              Reset Password
            </Link>
          </Text>

          <Button type="submit" minW="195px">
            Sign In
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

export default SignInPage;
